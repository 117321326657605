import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "../store/index"
import Login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue")
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "Users" */ "../views/User.vue")
  },
  {
    path: '/merchants',
    name: 'Merchants',
    component: () => import(/* webpackChunkName: "Merchants" */ "../views/Merchant.vue")
  },
  {
    path: '/institutions',
    name: 'Institutions',
    component: () => import(/* webpackChunkName: "Institutions" */ "../views/Institution.vue")
  },
  {
    path: '/transferts',
    name: 'Transferts',
    component: () => import(/* webpackChunkName: "Transferts" */ "../views/Transferts.vue")
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import(/* webpackChunkName: "Balance" */ "../views/Balances.vue")
  },
  {
    path: '/credits',
    name: 'Credits',
    component: () => import(/* webpackChunkName: "CreditBalance" */ "../views/CreditBalance.vue")
  },
  {
    path: '/update',
    name: 'Update',
    component: () => import(/* webpackChunkName: "Transactions" */ "../views/TransactionsUpdate.vue")
  },
  {
    path: '/validation',
    name: 'Validation',
    component: () => import(/* webpackChunkName: "Validation" */ "../views/Validation.vue")
  },
  {
    path: '/history',
    name: 'History',
    component: () => import(/* webpackChunkName: "History" */ "../views/History.vue")
  },
  {
    path: '/freshpay',
    name: 'FreshPay',
    component: () => import(/* webpackChunkName: "FreshPay" */ "../views/FreshPay.vue")
  },
  {
    path: '/pendings',
    name: 'Pendings',
    component: () => import(/* webpackChunkName: "FreshPay" */ "../views/PendingPayouts.vue")
  },
  {
    path: '/transactions-detail/:detail',
    name: 'TransactionDetail',
    component: () => import(/* webpackChunkName: "TransactionsDetail" */ "../views/TransactionsDetail.vue")
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const user = store.state.userlogged

  // we need to make ou router more efficient in order to be able to track other things
  if (!store.getters.login && to.path !== "/") {
    next('/')
  }
  else if (store.getters.login === true) {

    if(to.path === "/" && from.path !== '/'){
      window.sessionStorage.clear()
      window.location.reload()
      next('/')
    }

    if(to.path === "/freshpay" && user.level !== 5){
      next(from.path)
    }
    
    if(to.path === "/balance" && user.level < 4){
      next(from.path)
    }

    if(to.path === "/credits" && user.level < 4){
      next(from.path)
    }

    if(to.path === "/transferts" && user.level < 4){
      next(from.path)
    }

    if(to.path === "/update" && user.level < 2){
      next(from.path)
    }

    if(to.path === "/validation" && user.level < 2){
      next(from.path)
    }

    if(to.path === "/transactions-detail" && user.level < 3){
      next(from.path)
    }

    if(to.path === "/merchants" && user.level < 3){
      next(from.path)
    }

    if(to.path === "/users" && user.level < 3){
      next(from.path)
    }

    if(to.path === "/institutions" && user.level < 3){
      next(from.path)
    }

    next()
  }
  else {
    next()
  }
  
})

export default router
