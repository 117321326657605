<template>
  <v-app id="inspire">
    <v-navigation-drawer width="280" v-model="drawer" v-show="isLogin" app>
      <v-list>
        <v-list-item link to="/home">
          <v-list-item-icon>
            <v-icon>mdi-home-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group no-action :value="false" prepend-icon="mdi-shield-crown-outline" v-show="userlogged.level == 5">
          <template v-slot:activator>
            <v-list-item-title>Administrator</v-list-item-title>
          </template>
          <v-list-item link to="/freshpay">
            <v-list-item-title>FreshPay Users</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="false" prepend-icon="mdi-new-box" v-show="userlogged.level >= 3">
          <template v-slot:activator>
            <v-list-item-title>Creations</v-list-item-title>
          </template>
          <v-list-item link to="/users" v-show="userlogged.level >= 3">
            <v-list-item-title>Utilisateurs</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/merchants" v-show="userlogged.level >= 3">
            <v-list-item-title>Marchands</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/institutions" v-show="userlogged.level >= 3">
            <v-list-item-title>Institutions</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="false" prepend-icon="mdi-chart-timeline-variant" v-show="userlogged.level >= 4">
          <template v-slot:activator>
            <v-list-item-title>Business Reports</v-list-item-title>
          </template>
          <v-list-item link to="/transferts" v-show="userlogged.level >= 4">
            <v-list-item-title>Transferts & New Payouts</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/pendings" v-show="userlogged.level >= 4">
            <v-list-item-title>Pending Payouts</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/credits" v-show="userlogged.level >= 4">
            <v-list-item-title>Credit Balance</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/balance" v-show="userlogged.level >= 4">
            <v-list-item-title>Balance</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group no-action :value="false" prepend-icon="mdi-arrow-top-right-bottom-left">
          <template v-slot:activator>
            <v-list-item-title>Transactions</v-list-item-title>
          </template>
          <v-list-item link to="/update" v-show="userlogged.level >= 2">
            <v-list-item-title>Update Transactions</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/validation" v-show="userlogged.level >= 2">
            <v-list-item-title>Validations</v-list-item-title>
          </v-list-item>
          <v-list-item link to="/history">
            <v-list-item-title>History</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dense v-show="isLogin" app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>FreshPay DashBoard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-icon color="black" size="35" v-bind="attrs" v-on="on">mdi-account-circle</v-icon>
            {{userlogged.name.toUpperCase()}}
          </div>
        </template>
        <v-list>
          <v-list-item link @click="logout">Deconnexion</v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({ drawer: false }),
  computed: {
    ...mapState(["isLogin", "userlogged"])
  },
  methods: {
    ...mapActions(["setLoginAction"]),
    logout() {
      window.sessionStorage.clear()
      this.setLoginAction(false)
      this.$router.push("/")
      window.location.reload()
    }
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
</style>