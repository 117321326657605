import axios from "axios";

axios.defaults.baseURL = "/api/";

export default {
  login: async (data) => {
    let resp = await axios.post("login", data);
    return resp.data;
  },
  daily_all: async () => {
    let resp = await axios.get("daily_transactions");
    return resp.data;
  },
  search_transactions: async (data) => {
    let resp = await axios.post("transactions", data);
    return resp.data;
  },
  bulk_transactions: async (data) => {
    let resp = await axios.post("bulk-transactions", data);
    return resp.data;
  },
  update_transactions: async (data) => {
    let resp = await axios.post("update", data);
    return resp.data;
  },
  bulk_update_transactions: async (data) => {
    let resp = await axios.post("bulk-update", data);
    return resp.data;
  },
  transactions_history: async (dates) => {
    let resp = await axios.post("transaction-history", dates);
    return resp.data;
  },
  add_transfert: async (data) => {
    let resp = await axios.post("transfert", data);
    return resp.data;
  },
  create_institution_user: async (data) => {
    let resp = await axios.post("create-user", data);
    return resp.data;
  },
  create_institution: async (data) => {
    let resp = await axios.post("create-institution", data);
    return resp.data;
  },
  create_merchant: async (data) => {
    let resp = await axios.post("create-merchant", data);
    return resp.data.merchant;
  },
  get_merchants: async () => {
    let resp = await axios.get("merchants");
    return resp.data.merchants;
  },
  get_institutions: async () => {
    let resp = await axios.get("institutions");
    return resp.data;
  },
  get_institution_users: async () => {
    let resp = await axios.get("users");
    return resp.data.users;
  },
  get_institution_merchants: async () => {
    let resp = await axios.get("institution-merchants");
    return resp.data;
  },
  get_merchant_balance: async (data) => {
    let resp = await axios.post("merchant-balance", data);
    return resp.data;
  },
  get_credit_balances: async () => {
    let resp = await axios.get("credit-balance");
    return resp.data;
  },
  get_transferts: async () => {
    let resp = await axios.get("transferts");
    return resp.data.transferts;
  },
  get_roles: async () => {
    let resp = await axios.get("roles");
    return resp.data.roles;
  },
  create_role: async (data) => {
    let resp = await axios.post("create-role", data);
    return resp.data;
  },
  get_users: async () => {
    let resp = await axios.get("users-system");
    return resp.data.users;
  },
  create_user: async (data) => {
    let resp = await axios.post("users-system", data);
    return resp.data;
  },
  update_user: async (data) => {
    let resp = await axios.post("update-user", data);
    return resp.data;
  },
  get_orange_balance: async (data) => {
    let resp = await axios.post("orange-balance", data);
    return resp.data.balance;
  },
  get_payouts: async (data) => {
    let resp = undefined;

    if (data.length === 0) {
      resp = await axios.get(`pending-payouts`);
    } else {
      resp = await axios.get(
        `pending-payouts?dateStart=${data[0]}&dateEnd=${data[1]}`
      );
    }
    return resp.data.payouts;
  },
  replay_payouts: async (data) => {
    let resp = await axios.post("pending-payouts", data);
    return resp.data.success;
  },
  get_fin_payouts: async () => {
    let resp = await axios.get("payout");
    return resp.data.payouts;
  },
  makePayout: async (data) => {
    let resp = await axios.post("payout", data);
    return resp.data.success;
  },
  upload_file: async (data) => {
    let resp = await axios.post("file", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return resp.data.file;
  },
  topup: async (data) => {
    let resp = await axios.post("topup", data);
    return resp.data.success;
  },
};
