import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import services from "../services/services"

Vue.use(Vuex)

const state = {
  isLogin: false,
  userlogged: {
    "name":"",
    "role": "",
    "level": 0
  },
  transferts: [],
  institutions: [],
  airtel_report_charge: [],
  vodacom_report_charge: [],
  orange_report_charge: [],
  global_report_charge: [],
  airtel_report_payout: [],
  vodacom_report_payout: [],
  orange_report_payout: [],
  global_report_payout: [],
  global_charge_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  global_payout_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  airtel_charge_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  airtel_payout_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  vodacom_charge_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  vodacom_payout_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  orange_charge_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  orange_payout_details: {
    Successful: 0,
    Pending: 0,
    None: 0,
    Failed: 0,
    Submitted: 0
  },
  institutionMerchants: []
}

const mutations = {
  SET_LOGIN(state, value) {
    state.isLogin = value
  },
  CHANT_BALANCE(state, value) {
    state.merchant_balance = value
  },
  SET_DAILY_TRANSAC(state, value) {

    state.global_report_charge = value.global_charge
    state.global_report_payout = value.global_payout

    state.global_charge_details.Failed = value.global_charge.filter(el => el.status === "Failed" && el.action === "debit").length
    state.global_charge_details.Successful = value.global_charge.filter(el => el.status === "Successful" && el.action === "debit").length
    state.global_charge_details.Pending = value.global_charge.filter(el => el.status === "Pending" && el.action === "debit").length
    state.global_charge_details.None = value.global_charge.filter(el => el.status === "None" && el.action === "debit").length
    state.global_charge_details.Submitted = value.global_charge.filter(el => el.status === "Submitted" && el.action === "debit").length

    state.global_payout_details.Failed = value.global_payout.filter(el => el.status === "Failed" && el.action === "credit").length
    state.global_payout_details.Successful = value.global_payout.filter(el => el.status === "Successful" && el.action === "credit").length
    state.global_payout_details.Pending = value.global_payout.filter(el => el.status === "Pending" && el.action === "credit").length
    state.global_payout_details.None = value.global_payout.filter(el => el.status === "None" && el.action === "credit").length
    state.global_payout_details.Submitted = value.global_payout.filter(el => el.status === "Submitted" && el.action === "credit").length


    state.airtel_report_charge = value.airtel_charge
    state.airtel_report_payout = value.airtel_payout

    state.airtel_charge_details.Failed = value.airtel_charge.filter(el => el.status === "Failed" && el.action === "debit").length
    state.airtel_charge_details.Successful = value.airtel_charge.filter(el => el.status === "Successful" && el.action === "debit").length
    state.airtel_charge_details.Pending = value.airtel_charge.filter(el => el.status === "Pending" && el.action === "debit").length
    state.airtel_charge_details.None = value.airtel_charge.filter(el => el.status === "None" && el.action === "debit").length
    state.airtel_charge_details.Submitted = value.airtel_charge.filter(el => el.status === "Submitted" && el.action === "debit").length

    state.airtel_payout_details.Failed = value.airtel_payout.filter(el => el.status === "Failed" && el.action === "credit").length
    state.airtel_payout_details.Successful = value.airtel_payout.filter(el => el.status === "Successful" && el.action === "credit").length
    state.airtel_payout_details.Pending = value.airtel_payout.filter(el => el.status === "Pending" && el.action === "credit").length
    state.airtel_payout_details.None = value.airtel_payout.filter(el => el.status === "None" && el.action === "credit").length
    state.airtel_payout_details.Submitted = value.airtel_payout.filter(el => el.status === "Submitted" && el.action === "credit").length


    state.vodacom_report_charge = value.vodacom_charge
    state.vodacom_report_payout = value.vodacom_payout

    state.vodacom_charge_details.Failed = value.vodacom_charge.filter(el => el.status === "Failed" && el.action === "debit").length
    state.vodacom_charge_details.Successful = value.vodacom_charge.filter(el => el.status === "Successful" && el.action === "debit").length
    state.vodacom_charge_details.Pending = value.vodacom_charge.filter(el => el.status === "Pending" && el.action === "debit").length
    state.vodacom_charge_details.None = value.vodacom_charge.filter(el => el.status === "None" && el.action === "debit").length
    state.vodacom_charge_details.Submitted = value.vodacom_charge.filter(el => el.status === "Submitted" && el.action === "debit").length

    state.vodacom_payout_details.Failed = value.vodacom_payout.filter(el => el.status === "Failed" && el.action === "credit").length
    state.vodacom_payout_details.Successful = value.vodacom_payout.filter(el => el.status === "Successful" && el.action === "credit").length
    state.vodacom_payout_details.Pending = value.vodacom_payout.filter(el => el.status === "Pending" && el.action === "credit").length
    state.vodacom_payout_details.None = value.vodacom_payout.filter(el => el.status === "None" && el.action === "credit").length
    state.vodacom_payout_details.Submitted = value.vodacom_payout.filter(el => el.status === "Submitted" && el.action === "credit").length


    state.orange_report_charge = value.orange_charge
    state.orange_report_payout = value.orange_payout

    state.orange_charge_details.Failed = value.orange_charge.filter(el => el.status === "Failed" && el.action === "debit").length
    state.orange_charge_details.Successful = value.orange_charge.filter(el => el.status === "Successful" && el.action === "debit").length
    state.orange_charge_details.Pending = value.orange_charge.filter(el => el.status === "Pending" && el.action === "debit").length
    state.orange_charge_details.None = value.orange_charge.filter(el => el.status === "None" && el.action === "debit").length
    state.orange_charge_details.Submitted = value.orange_charge.filter(el => el.status === "Submitted" && el.action === "debit").length

    state.orange_payout_details.Failed = value.orange_payout.filter(el => el.status === "Failed" && el.action === "credit").length
    state.orange_payout_details.Successful = value.orange_payout.filter(el => el.status === "Successful" && el.action === "credit").length
    state.orange_payout_details.Pending = value.orange_payout.filter(el => el.status === "Pending" && el.action === "credit").length
    state.orange_payout_details.None = value.orange_payout.filter(el => el.status === "None" && el.action === "credit").length
    state.orange_payout_details.Submitted = value.orange_payout.filter(el => el.status === "Submitted" && el.action === "credit").length

  },
  SET_TRANSFERT(state, value) {
    state.transferts = value
  },
  SET_INST_MERCH(state, value){
    state.institutionMerchants = value
  },
  SET_INSTITUTION(state, value){
    state.institutions = value
  }
}

const actions = {
  setLoginAction({ commit }, value) {
    commit('SET_LOGIN', value)
  },
  async setDailyTransactionsAction({ commit }) {
    let data = await services.daily_all();
    commit('SET_DAILY_TRANSAC', data)
  },
  async setTransfertsAction({ commit }) {
    let data = await services.get_transferts()
    commit('SET_TRANSFERT', data)
  },
  async setInstutionMerchants({commit}){
    let data = await services.get_institution_merchants()
    commit('SET_INST_MERCH', data.data)
  },
  async setInstitutions({commit}){
    let data = await services.get_institutions();
    commit('SET_INSTITUTION', data.institutions)
  }
}

const getters = {
  login: state => state.isLogin
}

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
  plugins: [createPersistedState(
    { storage: window.localStorage }
  )]
})
