import Vue from 'vue'
import JsonCSV from 'vue-json-csv'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/routes'
import store from './store'

Vue.component('downloadCsv', JsonCSV)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
